<template>
  <topBanner />
  <div class="section">
    <div class="container">
      <div class="section-title centered mainSec">
        <span class="subtitle">{{ salesData.subtitle }}</span>
        <h3 class="title">{{ salesData.title }}</h3>
      </div>
      <div class="row">
        <div class="col-md-6" v-for="item in mainSalesData" :key="item.id">
          <div class="sigma_portfolio style-10">
            <div class="sigma_portfolio-thumb">
              <img :src="getItem(item.image)" alt="portfolio" />
              <div class="sigma_portfolio-content">
                <h5>
                  <a href="javascript:void(0)">{{ item.title }}</a>
                </h5> 
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import data from '../Data/data.json'
import topBanner from "../components/innerpagestopbanner/innerTop.vue";
export default {
  components: {
    topBanner,
  },
  data() {
    return {
      salesData: '',
      mainSalesData: ''
    };
  },
  created(){
    this.salesData = data.sales
    this.mainSalesData = data.sales.main_sales
  },
  methods: {
    getItem(pic){
      if(pic){
        return require('../assets/img/sales/' + pic)
      } else{
        return null
      }
    }
  }
};
</script>

<style> 
.mainSec{
    margin-bottom: 70px !important;
}
</style>